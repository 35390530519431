<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add Vehicle Finance
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Description"
            v-model="vehicleLoan.description"
            id="description"
            label="Description:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Credit Provider"
            v-model="vehicleLoan.creditProvider"
            id="creditProvider"
            label="Credit Provider:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <CurrencyField
            type="text"
            placeholder="0.00"
            id="amount"
            color="gold"
            v-model="vehicleLoan.total"
            :currencySymbol="defaultCurrency"
            :label="'Monthly Instalments In ' + defaultCurrencyName + ':'"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{parseFloat(Math.round((calculatedAmount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="vehicleLoan.total < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="zakaatVehicleLiabilities.length > 0">
        <div class="col-12 bold smallheading my-4">
          Vehicle
        </div>
      </div>
      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in zakaatVehicleLiabilities" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{liability.description}}
                </div>
                <div class="col-12 font12">
                  {{ liability.creditProvider }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, liability)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, liability)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{liability.description}}
                </div>
                <div class="col-12 font12">
                  {{ liability.creditProvider }}
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, liability)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, liability)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this vehicle finance?" :message="vehicleLoan.description" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddVehicle',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      defaultCurrencyName: process.env.VUE_APP_CURRENCY,
      vehicleLoan: {
        description: '',
        creditProvider: '',
        total: null
      },
      isEdit: false,
      isDelete: false,
      currentKey: null
    }
  },
  computed: {
    ...mapGetters([
      'zakaatVehicleLiabilities'
    ]),
    calculatedAmount () {
      if (this.vehicleLoan.total) {
        return parseFloat(this.vehicleLoan.total).toFixed(2)
      }
      return (0).toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'addToVehicle',
      'editVehicle',
      'deleteVehicleItem',
      'clearVehicle'
    ]),
    showDelete (key, val) {
      this.vehicleLoan = val
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.vehicleLoan = {
        description: '',
        creditProvider: '',
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deleteVehicleItem(this.currentKey)
      this.vehicleLoan = {
        description: '',
        creditProvider: '',
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.vehicleLoan = {
        description: '',
        creditProvider: '',
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.vehicleLoan.description = val.description
      this.vehicleLoan.creditProvider = val.creditProvider
      this.vehicleLoan.total = val.monthlyInstalments

      this.currentKey = key
    },
    async update () {
      const saveVehicleLoan = {
        key: '',
        item: {
          description: '',
          monthlyInstalments: 0,
          creditProvider: ''
        }
      }
      saveVehicleLoan.item.creditProvider = this.vehicleLoan.creditProvider
      saveVehicleLoan.item.description = this.vehicleLoan.description
      saveVehicleLoan.item.monthlyInstalments = this.vehicleLoan.total
      saveVehicleLoan.key = this.currentKey
      saveVehicleLoan.key = this.currentKey
      this.editVehicle(saveVehicleLoan)
      this.vehicleLoan = {
        description: '',
        creditProvider: '',
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      const saveVehicleLoan = {
        description: '',
        monthlyInstalments: 0,
        creditProvider: ''
      }
      saveVehicleLoan.creditProvider = this.vehicleLoan.creditProvider
      saveVehicleLoan.description = this.vehicleLoan.description
      saveVehicleLoan.monthlyInstalments = this.vehicleLoan.total
      this.addToVehicle(saveVehicleLoan)
      this.vehicleLoan = {
        description: '',
        creditProvider: '',
        total: null
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
